import React, { useState, useMemo } from "react";
import {
  alignPhoneNumber,
  capitalizeFirstLetter,
  dateAndTimeConverter,
  isDigit,
} from "../../Utils/constants";
import { isValidArray, isValidObject } from "../../Services/validators";
import SearchFallBackIcon from "../../Assets/SearchFallBackIcon.svg";
import Table from "../Table/Table";
import { ExportIcon } from "../../Assets/assets";
import { exportFiles } from "../../Services/api";
import "./ComplaintTable.css";
import { setComplaintsExportLoading } from "../../Redux/complaints/action";

export default function ComplaintsTable(props) {
  const [sortConfig, setSortConfig] = useState({
    title: "",
    ascending: true,
  });

  const sortedComplaints = useMemo(() => {
    if (!isValidObject(props.complaints?.data)) {
      return [];
    }

    const field = complaintsField(sortConfig.title);

    return Object.values(props.complaints?.data).sort((a, b) => {
      let nameA, nameB;
      switch (sortConfig?.title) {
        case "COMPLAINT ID":
        case "CATEGORY":
          nameA = a[field]?.toUpperCase() || "N/A";
          nameB = b[field]?.toUpperCase() || "N/A";
          break;
        case "PCT NO":
          nameA = a.location?.PCTNo?.toUpperCase() || "N/A";
          nameB = b.location?.PCTNo?.toUpperCase() || "N/A";
          break;
        case "RAISED BY":
          nameA = a.issuedBy?.userDetails?.[field]?.toUpperCase() || "N/A";
          nameB = b.issuedBy?.userDetails?.[field]?.toUpperCase() || "N/A";
          break;
        case "STATUS":
        case "UPDATED AT":
          nameA = a.status?.[field] || "N/A";
          nameB = b.status?.[field] || "N/A";
          break;
        case "RAISED AT":
          nameA = a?.[field] || "N/A";
          nameB = b?.[field] || "N/A";
          break;
        default:
          return 0;
      }

      if (isDigit(nameA) && isDigit(nameB)) {
        nameA = parseInt(nameA);
        nameB = parseInt(nameB);
      }
      if (nameA < nameB) {
        return sortConfig.ascending ? -1 : 1;
      }
      if (nameA > nameB) {
        return sortConfig.ascending ? 1 : -1;
      }
      return 0;
    });
  }, [props.complaints?.data, sortConfig]);

  const handleSort = (title) => {
    setSortConfig((prevConfig) => ({
      title,
      ascending: prevConfig.title === title ? !prevConfig.ascending : true,
    }));
  };

  const tableHeadings = [
    { title: "PCT NO", width: "80px" },
    { title: "COMPLAINT ID", width: "180px" },
    { title: "CATEGORY", width: "332px" },
    { title: "RAISED BY", width: "180px" },
    { title: "STATUS", width: "150px", textAlign: "center" },
    { title: "RAISED AT", width: "180px" },
    { title: "UPDATED AT", width: "180px" },
  ];

  const complaintTableData = useMemo(() => {
    if (!isValidArray(sortedComplaints)) {
      return [];
    }

    const data = [];
    for (let i = 0; i < sortedComplaints.length; i++) {
      const item = sortedComplaints[i];
      const transformedItem = {
        documentId: item?.documentId,
        locationId: item?.location?.PCTNo?.padStart(2, "0") || "N/A",
        category: item.category,
        name: isValidObject(item.issuedBy?.userDetails)
          ? `${alignPhoneNumber(item.issuedBy?.userDetails?.phoneNumber)}, ${
              item.issuedBy?.userDetails?.name
            }`
          : "N/A",
        currentStatus: capitalizeFirstLetter(item.status?.currentStatus),
        createdAt: item?.createdAt
          ? `${dateAndTimeConverter(
              item?.createdAt,
              "Time"
            )}, ${dateAndTimeConverter(item?.createdAt, "thirdDate")}`
          : "N/A",
        updatedAt: item?.status?.updatedAt
          ? `${dateAndTimeConverter(
              item.status?.updatedAt,
              "Time"
            )}, ${dateAndTimeConverter(item.status?.updatedAt, "thirdDate")}`
          : "N/A",
      };
      data.push(transformedItem);
    }

    return data;
    // eslint-disable-next-line
  }, [sortedComplaints, props.locations]);

  const downloadFile = async () => {
    try {
      setComplaintsExportLoading(true);

      const blob = await exportFiles(
        props.auth?.data.accessToken,
        props.complaintsFilterQuery
      );

      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;

      link.setAttribute("download", "PCT Location Complaint Counts.xlsx");
      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
      setComplaintsExportLoading(false);
    } catch (error) {
      console.error("There was an error downloading the file:", error);
      setComplaintsExportLoading(false);
    }
  };

  return (
    <div
      className="remaining-body-height-from-footer inherit-parent-height"
      style={{
        minWidth: "700px",
      }}
      data-cy={"complaints-table"}
    >
      {isValidArray(sortedComplaints) && (
        <Table
          showSummary={true}
          headers={tableHeadings}
          paginationLoading={
            props.complaintsLoading.pagination ||
            props.complaints.paginationLoading
          }
          onScrollReachedEnd={() => {
            if (props.complaints.paginationLoading === false) {
              props.getComplaintsNextPage();
            }
          }}
          summaryClick={props.summaryClick}
          falseData={
            <div
              onClick={() => {
                if (
                  !props.complaints.exportLoading &&
                  complaintTableData.length > 0
                ) {
                  downloadFile();
                }
              }}
              style={props.complaints.exportLoading ? { opacity: "50%" } : {}}
              className="display-flex flex-justify-content-center flex-align-items-center cursor-pointer"
            >
              <div>
                {props.complaints.exportLoading ? (
                  <div className="background-color-white border-radius-default ">
                    <div className="export-loader" data-cy="qr-loader" />
                  </div>
                ) : (
                  <div style={{ width: "16px", height: "16px" }}>
                    <ExportIcon />
                  </div>
                )}
              </div>
              <div className="padding-left-default padding-top-small">
                EXPORT
              </div>
            </div>
          }
          data={complaintTableData}
          onClick={(data) => {
            props.setSelectedTableId(data?.documentId);
          }}
          handleSort={(data) => {
            handleSort(data);
          }}
          sort={sortConfig}
          getField={complaintsField}
          theme={props.theme}
        />
      )}

      {!isValidArray(sortedComplaints) && (
        <div
          className="parent-height-sub-42px inherit-parent-width font-size-default display-flex flex-direction-column flex-justify-content-center flex-align-items-center"
          data-cy="no-search-or-filter-fallback"
        >
          <img src={SearchFallBackIcon} alt="no filter result found" />
          <div className=" padding-top-larger">NO MATCHING RESULTS</div>
          <div className=" padding-top-large">
            There wasn’t any result matching to the search and filter
          </div>
        </div>
      )}
    </div>
  );
}

function complaintsField(data) {
  switch (data) {
    case "COMPLAINT ID":
      return "documentId";
    case "CATEGORY":
      return "category";
    case "PCT NO":
      return "locationId";
    case "RAISED BY":
      return "name";
    case "STATUS":
      return "currentStatus";
    case "RAISED AT":
      return "createdAt";
    case "UPDATED AT":
      return "updatedAt";

    default:
      return data?.toLowerCase();
  }
}
